import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tableThemer } from '../../../themes';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import MaterialTable from 'material-table';
import { axios, CancelToken } from '../../../services/networkRequest';
import GameProgress from './YoungPersonComponents/GameProgress';
import ContactDetails from './YoungPersonComponents/ContactDetails';
import Warning from '@material-ui/icons/Warning';
import Edit from '@material-ui/icons/Edit';
import style, {
  activeColor,
  dropdownTextColor,
  greyBorderColor,
  listBackgroundColor,
  notActiveColor,
  numberTextColor,
  tableItemBorderColor,
} from '../../../Styles';
import { genericAlert, getChain, identity } from '../../../services/helpers';
import * as PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import GameAsset from '@material-ui/icons/VideogameAssetOutlined';
import Timer from '@material-ui/icons/Timer';
import Footer from '../../content/Footer';
import UsageGraph from '../UsageGraph';
import { useTranslation } from 'react-i18next';
import { translationKey } from '../../../utilities/localisation/translationKeys';
import { TypographyAverageTime, TypographyHoursAndMinutes } from '../../Typography/TypographyNumberSpan';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import ArrowDownwardSharp from '@material-ui/icons/ArrowDownwardSharp';
import { useUserContext } from '../../../store/UserContext';

const GET_YOUNG_PERSONS_URL = '/ln/young_persons';
const INITIAL_PAGINATOR_STATE = {currentPage: 0, currentPageSize: 25, perPage: 25, pages: 1, totalCount: 0};
const dataViews = {
  active: "active",
  inactive: "inactive",
  notYetActive: "not_yet_active"
}

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: '4px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  table: {
    marginTop: '20px',
  },
  pos: {
    marginBottom: 12,
  },
  center: {
    display: 'block',
    margin: '0 auto',
  },
  left: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    marginTop: '8px',
  },
  right: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginTop: '8px',
  },
  button: {
    display: 'inline-block',
    textAlign: 'right',
    float: 'right',
    marginBottom: '16px',
    '&:hover': {
      backgroundColor: '#0079C6',
    },
    '&:active': {
      backgroundColor: 'white',
      borderColor: 'white',
      color: '#0079C6',
    },
  },
  block: {
    display: 'block',
    width: '100%',
  },
  tableItem: {
    border: `1px solid ${ tableItemBorderColor }`,
    marginTop: '10px',
  },
  greenBorder: {
    borderLeft: `10px solid ${ activeColor }`,
  },
  paddedItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  text: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    paddingTop: '50px',
  },
  textSpace: {
    paddingTop: '20px',
  },
  buttonSpace: {
    paddingTop: '40px',
  },
  deactivateButton: {
    marginLeft: 'auto',
    color: '#3B3B3B',
    textDecoration: 'underline',
  },
  youngPersonActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  select: {
    color: dropdownTextColor,
    border: `1px solid ${ dropdownTextColor }`,
    fontSize: '14px',
    padding: '2px 10px',
    minWidth: '160px',
    '&:focus': {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: `1px solid ${ dropdownTextColor }`,
      outlineStyle: 'none'
    }
  },
  tabs: {
    minWidth: 275,
    marginBottom: '4px',
    backgroundColor: '#FAFAFA',
    borderBottom: '2px solid #E6E6E6'
  },
  tab: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    marginTop: '8px',
    cursor: 'pointer',
    padding: '1em',
  },
  activeTab: {
    display: 'inline-block',
    textAlign: 'left',
    float: 'left',
    marginTop: '8px',
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundColor: '#E6E6E6',
    padding: '1em',
    borderBottom: '2px solid #113982'
  }
});

const order = new Proxy({
  'complete': 1,
  'started': 2,
  'incomplete': 3,
}, {
  get: (target, key) => target[key] || 4,
});

export const setter = (list, updater) => (rowData) => {
  const index = list.findIndex(yp => yp.id === rowData.id);
  if (index > -1) {
    // shallow copy is need for react to know we changed the state or a re-render doesn't trigger
    const newList = [...list];
    newList[index] = rowData;
    updater(newList);
  }
};
function downloadCSV(yp_status) {
  axios.get(`/ln/young_persons/download/${yp_status}`, {
    headers: {
      'Accept': 'text/csv',
      responseType: 'arraybuffer',
    },
  })
    .then(res => {
      let now = new Date()

      fileDownload(res.data, `${ now.getDate() }_${ now.getMonth() + 1 }_${ now.getFullYear() }_luminova_yp_${ yp_status }.csv`, res.headers['content-type']);
    })
}

export default function GamersPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {user} = useUserContext()
  const [youngPersons, setYoungPersons] = useState([]);
  const [notStartedYoungPersons, setNotStartedYoungPersons] = useState([]);
  const [optedOutYoungPersons, setOptedOutYoungPersons] = useState([]);
  const [gameKeys, setGameKeys] = useState({ alert: false, totalKeys: 0, usedKeys: 0 });
  const [allPeople, setAllPeople] = useState(undefined);
  const [dataState, setDataState] = useState(false);
  const [isSelfSignUpDist, setIsSelfSignUpDist] = useState(false);
  const [activePaginator, updateActivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })
  const [notYetActivePaginator, updateNotYetActivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })
  const [inactivePaginator, updateInactivePaginator] = useState({
    ...INITIAL_PAGINATOR_STATE
  })

  useEffect(() => {
    axios.get(`/entity/${user.entity_id}/settings`).then(({data}) => {
      data && data.self_sign_up_settings.distributor_id === user.id && setIsSelfSignUpDist(true);
    })
  }, [user])

  const tabs = {
    Active: "active",
    NotStarted: "not started",
    Deactivated: "deactivated"
  }

  const currentTab = (props.history.location.state && props.history.location.state.newYoungPerson) ? tabs.NotStarted : tabs.Active;
  const [activeTab, setActiveTab] = useState(currentTab);

  const options = useMemo(() => {
    return {
      detailPanelColumnAlignment: 'right',
      detailPanelType: 'single',
      paging: true,
      pageSize: 25, 
      pageSizeOptions: [],
      initialPage: 0,
      emptyRowsWhenPaging: false,
      search: false,
      sorting: false,
      showTitle: false,
      toolbar: false,
      headerStyle: {
        backgroundColor: listBackgroundColor,
      },
    }
  }, []);

  const reloadData = () => {
    setDataState(!dataState);
    setAllPeople(undefined);
    setYoungPersons([]);
    setNotStartedYoungPersons([]);
    setOptedOutYoungPersons([]);
    // Checks the active tab and reloads the day by switching tabs. The data is also refreshed for cases of deactivation and deletion
    if(activeTab === tabs.Active)
    {
      setActiveTab(tabs.Deactivated)
      setActiveTab(tabs.Active)

    }
    else if(activeTab === tabs.Deactivated)
    {
      setActiveTab(tabs.Active)
      setActiveTab(tabs.Deactivated)
    }
    else if(activeTab === tabs.NotStarted)
    {
      setActiveTab(tabs.Deactivated)
      setActiveTab(tabs.NotStarted)
    }
  };

  useEffect(() => {
    let cancel;
    const cancelToken = new CancelToken(c => {
      cancel = c;
    });


    const populateAllTables = async () => {
      const per_page = 25;
      const page = 0

      try {
        // Collective API calls. 
        const [data_active, data_inactive, data_not_yet_active] = await Promise.all([
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.active }}).catch(identity),
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.inactive }}).catch(identity),
          axios.get(GET_YOUNG_PERSONS_URL, { cancelToken, params: { per_page, page, view: dataViews.notYetActive }}).catch(identity)
        ])

        const { alert, total_keys, used_keys, active } = data_active.data
        const { inactive } = data_inactive.data
        const { not_yet_active } = data_not_yet_active.data

        // State Updates
        setGameKeys({ alert: alert, totalKeys: total_keys, usedKeys: used_keys });
        setAllPeople(active.data.length + inactive.data.length + not_yet_active.data.length)
        // const sorted = active.data.sort(function(a, b) {
        //   return new Date(b.last_seen) - new Date(a.last_seen)
        // });
        
        setYoungPersons(active.data);
        setNotStartedYoungPersons(not_yet_active.data);
        setOptedOutYoungPersons(inactive.data);

        // Paginator States
        updateActivePaginator({
          currentPage: active.current_page, 
          currentPageSize: active.data.length,
          perPage: active.per_page, 
          pages: active.pages, 
          totalCount: active.total_count
        })
        updateInactivePaginator({
          currentPage: inactive.current_page, 
          currentPageSize: inactive.data.length,
          perPage: inactive.per_page, 
          pages: inactive.pages, 
          totalCount: inactive.total_count
        })
        updateNotYetActivePaginator({
          currentPage: not_yet_active.current_page,
          currentPageSize: not_yet_active.data.length,
          perPage: not_yet_active.per_page, 
          pages: not_yet_active.pages, 
          totalCount: not_yet_active.total_count
        })
      }
      catch(err) {}
    }
    populateAllTables();

    return () => {
      cancel();
    };
  }, [dataState]);

  const renderZeroState = () => {
    return (
      <Container component="main" maxWidth="lg">
        <Card className={ classes.card }>
          <CardContent>
            <Grid container className={ classes.text } direction='column' alignItems='center' justify='center'>
              <Grid item xs={ 6 }>
                <Typography component="h5" align="center" variant="h5" style={ { fontWeight: 800 } }>
                  {t(translationKey.LabelInviteYoungPerson)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } justify='center' className={ classes.buttonSpace }>
              <div>
                <Button variant="contained" color="primary"
                        className={ classes.button }
                        onClick={ () => {
                          props.history.push('/ln/young_person/create');
                        } }>
                  {t(translationKey.ButtonInviteYoungPerson)}
                </Button>
              </div>
            </Grid>
            <div style={ { margin: '25px -50px', borderTop: `${ numberTextColor } solid 4px` } } />
            <CardMedia image="/GettingStartedLn.jpg" title="" style={ { height: 700 } } />
          </CardContent>
        </Card>
        <Footer />
      </Container>
    );
  };

  const RenderDetailFactory = (borderColour, setter) => (rowData) => <RenderDetails
    setYoungPersonData={ setter }
    borderColour={ borderColour }
    reloadData={ reloadData }
    isSelfSignUpDist={isSelfSignUpDist}
    rowData={ rowData } { ...props } />;

  const renderList = props => {
    return (
      <Container component="main" maxWidth="lg" style={ { marginTop: '10px' } }>
        <Card className={ classes.card }>
          <CardContent>
            <div className={ classes.block }>
              <Typography className={ classes.left } color="textSecondary">
                <strong>{t(translationKey.TitleLicensesUsed)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{ gameKeys.alert ?
                <Warning htmlColor='black' style={ {
                  height: '0.7em',
                  marginBottom: '-0.12em',
                } } /> : null }{t(translationKey.LabelLicensesUsed,{ used: gameKeys.usedKeys, total: gameKeys.totalKeys})}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                className={ classes.button }
                onClick={ () => props.history.push('/ln/young_person/create') }>
                {t(translationKey.ButtonInviteYoungPerson)}
              </Button>

            </div>
          </CardContent>
        </Card>

        <Card elevation={0} className={ classes.tabs }>
            <div className={ classes.block }>
              <Typography
                className={ activeTab === tabs.NotStarted ? classes.activeTab : classes.tab}
                onClick={() => {
                  setActiveTab(tabs.NotStarted);
                }}
              >
                {t(translationKey.LabelYoungPeopleNotYetStartedTab, { count: notYetActivePaginator.totalCount })}
              </Typography>

              <Typography
                className={ activeTab === tabs.Active ? classes.activeTab : classes.tab}
                onClick={() => {
                  setActiveTab(tabs.Active);
                }}
              >
                {t(translationKey.LabelYoungPeoplePlayingTheGame, { count: activePaginator.totalCount })}
              </Typography>

              <Typography
                className={ activeTab === tabs.Deactivated ? classes.activeTab : classes.tab}
                onClick={() => {
                  setActiveTab(tabs.Deactivated);
                }}
              >
                {t(translationKey.LabelYoungPeopleDeactivatedTab, { count: inactivePaginator.totalCount })}
              </Typography>

              <YoungPersonDownloadButton filtering={'all'} />

            </div>
        </Card>

        { activeTab === tabs.Active &&
          <Grid container direction={"column"}>
            <Grid item >
              <YoungPersonDownloadButton filtering={ 'startedplaying' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(youngPersons) }>
              <MaterialTable
                
                isLoading={ !youngPersons }
                columns={ [
                  {
                    title: t(translationKey.TitleYoungPerson),
                    field: 'first_name',
                    cellStyle: {
                      borderLeft: `20px solid ${ activeColor }`,
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleStartedGame),
                    field: 'first_play',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleLastSeen),
                    field: "last_seen",
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.last_seen && new Date(rowData.last_seen).toDateString() }</Typography>
                  },
                  {
                    title: t(translationKey.TitleChallenges),
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ getChain(rowData, 'counts', 'challenges') || 0 }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleGoals),
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ getChain(rowData, 'counts', 'goals') || 0 }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleGameKey),
                    field: 'game_key',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
                  },
                ] }
                data={  (query) => 
                  new Promise(async (resolve, reject) => {

                    axios.get(GET_YOUNG_PERSONS_URL, { params: { per_page: INITIAL_PAGINATOR_STATE.perPage, page: query.page, view: dataViews.active } })
                    .then((response) => {
                      const {alert, total_keys, used_keys} = response.data 
                      const { data, current_page, per_page, pages, total_count } = response.data.active;
                      if(allPeople === 0)
                        setAllPeople(data.length)
                      setGameKeys({ alert: alert, totalKeys: total_keys, usedKeys: used_keys });
                      setYoungPersons(data);
                      updateActivePaginator({
                        currentPage: current_page, 
                        currentPageSize: data.length,
                        perPage: per_page, 
                        pages: pages, 
                        totalCount: total_count
                      })
                      resolve({                          
                        data: data,
                        page: current_page,
                        totalCount: total_count,
                      })
                    }).catch(identity)
                  })
                  // youngPersons
                 }
                detailPanel={ RenderDetailFactory(activeColor, setter(youngPersons, setYoungPersons)) }
                onRowClick={ (event, rowData, togglePanel) => togglePanel() }
                options={ {...options}  }
                icons={ { DetailPanel: ChevronRight } }
                style={ { backgroundColor: listBackgroundColor } }
              />
            </ThemeProvider>
          </Grid>

        }

        { activeTab === tabs.NotStarted &&
          <Grid container direction={"column"}>
            <Grid item >
              <YoungPersonDownloadButton filtering={ 'notstarted' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(notStartedYoungPersons) }>
              <MaterialTable
                
                isLoading={ !notStartedYoungPersons }
                columns={ [
                  {
                    title: t(translationKey.TitleYoungPerson),
                    field: 'first_name',
                    cellStyle: {
                      borderLeft: `20px solid ${ notActiveColor }`,
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleInvited),
                    field: 'created_at',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleStartedGame),
                    field: 'first_play',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleGameKey),
                    field: 'game_key',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
                  }
                ] }
                data={ (query) =>
                  new Promise((resolve, reject) => {
                    axios.get(GET_YOUNG_PERSONS_URL, { params: { per_page: INITIAL_PAGINATOR_STATE.perPage, page: query.page, view: dataViews.notYetActive } })
                    .then((response) => {
                      const { data, current_page, per_page, pages, total_count } = response.data.not_yet_active;
                      if(allPeople === 0)
                        setAllPeople(data.length)
                      setNotStartedYoungPersons(data);
                      updateNotYetActivePaginator({
                        currentPage: current_page, 
                        currentPageSize: data.length,
                        perPage: per_page, 
                        pages: pages, 
                        totalCount: total_count
                      })
                      resolve({                          
                        data: data,
                        page: current_page,
                        totalCount: total_count,
                      })
                    }).catch(identity)
                  })
                  // notStartedYoungPersons
                 }
                detailPanel={ RenderDetailFactory(notActiveColor, setter(notStartedYoungPersons, setNotStartedYoungPersons)) }
                onRowClick={ (event, rowData, togglePanel) => togglePanel() }
                options={ {...options} }
                icons={ { DetailPanel: ChevronRight } }
                style={ { backgroundColor: listBackgroundColor } }
              />
            </ThemeProvider>
          </Grid>
        }

        { activeTab === tabs.Deactivated &&
          <Grid container direction={"column"}>
            <Grid item >
              <YoungPersonDownloadButton filtering={ 'deactivated' } />
            </Grid>
            <ThemeProvider theme={ tableThemer(optedOutYoungPersons) }>
              <MaterialTable
                
                isLoading={ !optedOutYoungPersons }
                columns={ [
                  {
                    title: t(translationKey.TitleYoungPerson),
                    field: 'first_name',
                    cellStyle: {
                      borderLeft: `20px solid ${ greyBorderColor }`,
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textPrimary">{ `${ rowData.first_name } ${ rowData.last_name }` }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleInvited),
                    field: 'created_at',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.created_at && new Date(rowData.created_at).toDateString() }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleStartedGame),
                    field: 'first_play',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography
                      color="textSecondary">{ rowData.first_play && new Date(rowData.first_play).toDateString() }</Typography>,
                  },
                  {
                    title: t(translationKey.TitleGameKey),
                    field: 'game_key',
                    cellStyle: {
                      borderRight: 'none',
                    },
                    render: rowData => <Typography color="textSecondary">{ rowData.game_key }</Typography>,
                  }
                ] }
                data={ (query) =>
                  new Promise((resolve, reject) => {
                    axios.get(GET_YOUNG_PERSONS_URL, { params: { per_page: INITIAL_PAGINATOR_STATE.perPage, page: query.page, view: dataViews.inactive } })
                    .then((response) => {
                      const { data, current_page, per_page, pages, total_count } = response.data.inactive;
                      if(allPeople === 0)
                        setAllPeople(data.length)
                      setOptedOutYoungPersons(data);
                      updateInactivePaginator({
                        currentPage: current_page,
                        currentPageSize: data.length,
                        perPage: per_page, 
                        pages: pages, 
                        totalCount: total_count
                      })
                      resolve({                          
                        data: data,
                        page: current_page,
                        totalCount: total_count,
                      })
                    }).catch(identity)
                  }) 
                  // optedOutYoungPersons 
                }
                detailPanel={ RenderDetailFactory(greyBorderColor, setter(optedOutYoungPersons, setOptedOutYoungPersons)) }
                onRowClick={ (event, rowData, togglePanel) => togglePanel() }
                options={ {...options} }
                icons={ { DetailPanel: ChevronRight } }
                style={ { backgroundColor: listBackgroundColor } }
              />
            </ThemeProvider>
          </Grid>
        }
        <Footer />
      </Container>
    );
  };

  return (
    <>
      { allPeople === 0 ? renderZeroState() : renderList(props) }
    </>
  );
}

const sorting = progress => {
  return progress && progress.sort((a, b) => order[a.status] - order[b.status]);
};

export function RenderDetails(props) {
  const {
    rowData,
    setYoungPersonData,
    reloadData,
    borderColour,
    isSelfSignUpDist
  } = props;
  const classes = useStyles();
  const [userData, setUserData] = useState(rowData);
  const { t } = useTranslation();


  useEffect(() => {
    if (!getChain(userData, 'stats')) {
      axios.get(`/ln/young_persons/${ userData.id }`)
        .then(({ data }) => {
          userData.stats = data;
          setUserData(userData);
          setYoungPersonData(userData);
        })
        .catch(error => {
          genericAlert(t(translationKey.TitleError), getChain(error, 'response', 'data', 'error'));
        });
    }

  }, [userData, setYoungPersonData, t]);

  console.log(rowData)

  const { tableData, ...restUserData } = userData;
  const player = userData.first_name;
  const progress = sorting(getChain(userData, 'stats', 'progress'));
  const gameTime = getChain(userData, 'stats', 'game_time');
  const invitedDate = rowData && rowData.created_at && new Date(rowData.created_at);
  const localeInvitedDate = dayjs(invitedDate).format(t(translationKey.DateFormat));
  const averageTime = gameTime ? gameTime.timeAverage : '';
  const totalTime = gameTime && gameTime.timeSpent;
  const totalSessions = gameTime ? gameTime.totalSessions : '';
  const totalHours = gameTime ? Math.floor(totalTime / 60) : '';
  const totalMinutes = gameTime ? Math.round(totalTime - (totalHours * 60)) : '';

  return (
    <div style={ {
      backgroundColor: listBackgroundColor,
      position: 'relative',
      width: 'calc(100% + 1px)',
      borderLeft: `20px solid ${ borderColour }`,
      borderRight: `1px solid ${ greyBorderColor }`,
      boxSizing: 'border-box',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: '-18px',
    } }
         key={ userData.id }
    >
      <Grid container style={ { width: 'inherit', margin: 0, paddingLeft: '30px', paddingTop: '30px' } }
            spacing={ 3 }>
        { userData.first_play && <>
          <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Grid container>
                  <Grid item xs={ 6 }><Typography color="textPrimary">{t(translationKey.TitlePlayerGameTime, { player })}</Typography></Grid>
                  <Grid item xs={ 6 }><Typography color="textSecondary"
                                                  align="right">{(t(translationKey.TitleInvitedOn, { invitedOnDateString : localeInvitedDate|| '' }))}</Typography></Grid>
                </Grid>
              </CardContent>
              {/* <WeeklyGameTime gameTime={ gameTime } firstName={ userData.first_name } /> */ }
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.TitleTotalGameSessions)} </Typography>
                <Grid container>
                  <Grid item xs={ 10 }><Typography
                    style={ { fontSize: '30px', color: numberTextColor } }>{ totalSessions }</Typography></Grid>
                  <Grid item xs={ 2 }><GameAsset style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelAverageSessionTime)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <TypographyAverageTime average={averageTime} />
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={ 4 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textPrimary">{t(translationKey.LabelTotalTimePlaying)}</Typography>
                <Grid container>
                  <Grid item xs={ 10 }>
                    <TypographyHoursAndMinutes totalHours={totalHours} totalMinutes={totalMinutes} />
                  </Grid>
                  <Grid item xs={ 2 }><Timer style={ { fontSize: '40px' } } /></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <UsageGraph classes={classes} playerName={player} userData={userData} setUserData={(userData) => {
            setUserData(userData);
            setYoungPersonData(userData);
          } } chartUrl={`/ln/young_persons/${ userData.id }/chart`} />
          { progress && progress.length > 0 && <Grid item xs={ 12 }>
            <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
              <CardContent>
                <Typography color="textSecondary">{t(translationKey.TitlePlayerGameProgress, { player })}</Typography>
                <GameProgress progress={ progress } />
              </CardContent>
            </Card>
          </Grid> }
        </> }
        <Grid item xs={ 12 }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Grid container>
                <Grid item xs={ 10 }>
                  <Typography color="textSecondary">{t(translationKey.LabelContact)}</Typography>
                </Grid>
                <Grid item xs={ 2 }>
                  <Grid container>
                    <Grid item xs={ 6 } style={ { marginLeft: 'auto' } }>
                      <Button
                        onClick={ () => {
                          const { dob, ethnicity, first_name, gender, guardians, has_disability, id, last_name, post_code, service_user_reference, country, test_account, custom_entry_one_response, custom_entry_two_response, custom_entry_three_response } = userData;
                          props.history.push(`/ln/young_person/${ id }/edit`, {
                            youngPerson: {
                              dob,
                              ethnicity,
                              first_name,
                              gender,
                              guardians,
                              has_disability,
                              id,
                              last_name,
                              post_code,
                              country,
                              service_user_reference,
                              test_account,
                              custom_entry_one_response,
                              custom_entry_two_response,
                              custom_entry_three_response,
                            },
                          });
                        } }
                      >
                        <Edit htmlColor={ greyBorderColor } />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ContactDetails { ...userData } isSelfSignUpDist={isSelfSignUpDist} started={progress && progress.length > 0} />
            </CardContent>
          </Card>
        </Grid>
        { userData.id &&
        <Grid item xs={ 12 }>
          <Card style={ { border: `1px solid ${ greyBorderColor }` } }>
            <CardContent>
              <Link to={ `/ln/survey_results/${ userData.id }` }>
                <Typography color="textSecondary" style={ { textDecoration: 'underline' } }>{t(translationKey.ButtonGoalBasedOutcomes)}</Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        }
        <Grid item className={ classes.deactivateButton }
              style={ { display: 'flex', justifyContent: 'space-between', width: '100%' } }>
          <Grid item xs={ 3 }>
            <ThemeProvider theme={ style }>
              <Button fullWidth variant="contained" color="primary"
                      onClick={ () => {
                        props.history.push(`/ln/young_person/${ userData.id }/report`, restUserData);
                      } }>
                {t(translationKey.ButtonDownloadReport)}
              </Button>
            </ThemeProvider>
          </Grid>
          { userData.deleted_at ?
            <RemovalButton classes={ classes } title={t(translationKey.TitleDeleteYoungPerson)} action={ () => {
              genericAlert({
                title: t(translationKey.AlertTitleConfirmDeleteYoungPerson),
                message: t(translationKey.AlertBodyConfirmDeleteYoungPerson),
                negativeTitle: t(translationKey.LabelCancel),
                positiveTitle: t(translationKey.LabelDelete),
                positiveCallback: () => {
                  axios
                    .delete(`/ln/young_persons/${ userData.id }/anonymise`)
                    .then(reloadData);
                },
              });
            } } />
            : <RemovalButton classes={ classes } title={t(translationKey.TitleDeactivateGameKey)} action={ () => {
              axios.get('/ln/young_persons/deactivation_reason')
                .then(({ data }) => {
                  let reason;
                  const setReason = (r) => {
                    reason = r;
                  };
                  genericAlert({
                    title: t(translationKey.AlertTitleDeactivationReason),
                    component: () => <ReasonSelection data={ data } setReason={ setReason } />,
                    negativeTitle: t(translationKey.LabelCancel),
                    positiveTitle: t(translationKey.ButtonSubmit),
                    positiveCallback: () => {
                      const { id } = userData;
                      if (id) {
                        axios.delete(`/ln/young_persons/${ id }`, {
                          data: {
                            reason,
                          },
                        })
                          .then(reloadData)
                          .catch(identity);
                      }
                    },
                  });
                });
            } }
            />
          }
        </Grid>
      </Grid>
    </div>
  );
}

const RemovalButton = ({ title, action, classes }) =>
  <Button className={ classes.deactivateButton } onClick={ action }>
    { title }
  </Button>;

RemovalButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

RenderDetails.propTypes = {
  borderColour: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
  setYoungPersonData: PropTypes.func.isRequired,
};

const ReasonSelection = ({ data, setReason }) => {
  const [internalReason, setInternalReason] = useState();
  const { t } = useTranslation();
  return (
    <form>
      <FormControl component="fieldset">
        <FormLabel component="legend">Reason</FormLabel>
        <RadioGroup aria-label={t(translationKey.LabelReason)} name='reason'
                    onChange={ event => {
                      let value = parseInt(event.target.value);
                      setReason(value);
                      setInternalReason(value);
                    } }>
          {
            data.map(each => <FormControlLabel key={ each.id } value={ each.id } control={ <Radio /> }
                                               label={ each.value } checked={ each.id === internalReason } />)
          }
        </RadioGroup>
      </FormControl>
    </form>
  );
};

const YoungPersonDownloadButton = ({ filtering }) => {
  const { t } = useTranslation();
  return(
    <div
      style={ {
        width: 'fit-content',
        cursor: 'pointer',
        marginRight: '1em',
        textAlign: 'right',
        float: 'right',
        marginTop: '8px',
        backgroundColor: '#E6E6E6',
        padding: '0.5em',
      } }
      onClick={ () => {
        downloadCSV(filtering);
      } }>

      <Grid container>
        <ArrowDownwardSharp />
        <Typography> {filtering === 'all' ? t(translationKey.LabelDownloadAllYoungPeople) : t(translationKey.LabelDownloadYoungPeople)} </Typography>
      </Grid>
    </div>
  );
};

ReasonSelection.propTypes = {
  data: PropTypes.array.isRequired,
  setReason: PropTypes.func.isRequired,
};
